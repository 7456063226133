import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { FcExpired } from "react-icons/fc";
import { useSelector } from "react-redux";
import { setDefaultState } from "../../redux/slice/UserSlice";
import ModalDisplay from "../modal";
import ModalContent from "../modal/modalContent";
import { withRouter } from "../with-router";

const parseJwt = (token) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  let location = props.router.location;
  const userLogin = useSelector((state) => state.user.credentials);
  const { token } = userLogin;
  const [sessionModal, setSessionModal] = useState(false);
  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        setSessionModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      <ModalDisplay
        title={
          <label className="menubarRightButtons deleteModalTitle">
            <FcExpired size={50} />
            Session Expired
          </label>
        }
        openModal={sessionModal}
        footerDisplay={false}
        modalContentJSX={() =>
          ModalContent(
            "Your session on SkillsDa is expired, Please login again to continue !",
            () => {},
            () => {
              props.router.dispatch(setDefaultState());
              props.router.navigate("/");
              setSessionModal(false);
            },
            false,
            true,
            "No",
            "Login"
          )
        }
      />
    </div>
  );
};

export default withRouter(AuthVerify);
