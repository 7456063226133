import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Drawer,
  Grid,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineExperiment } from "react-icons/ai";
import { BiBookAdd, BiFolderOpen, BiSupport } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { FaRegUserCircle } from "react-icons/fa";
import { GoRepo } from "react-icons/go";
import {
  MdOutlineAccountBox,
  MdOutlineDashboard,
  MdOutlineLibraryAdd,
  MdSupervisorAccount,
} from "react-icons/md";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import darkLogo from "../../../assets/img/logo//SkillsDA Logo with Text - White Colour.png";
import Buttons from "../../../components/Button";
import ModalDisplay from "../../../components/modal";
import Toast from "../../../components/toast";
import { getAllRepoRequest } from "../../../redux/service/LabService";
import { setDefaultState } from "../../../redux/slice/UserSlice";
import {
  setCollapse,
  setDrawer,
  setLoading,
} from "../../../redux/slice/constant";
import { GlobalLabels } from "../../../utils/constants/common";

const SideBar = () => {
  const { Sider } = Layout;
  const { Text } = Typography;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const location = useLocation();
  const [logoutConfimmodalOpen, setlogoutConfimmodalOpen] = useState(false);
  const drawerCollapse = useSelector((state) => state.constant.drawerCollapse);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.user.credentials);
  const labCategory = useSelector((state) => state.labs.labCategory);
  const labError = useSelector((state) => state.labs.error);
  const siderCollapse = useSelector((state) => state.constant.siderCollapse);
  const [subMenu, setSubmMenu] = useState([]);
  const [current, setCurrent] = useState(location.pathname);
  const { token } = userLogin;

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getAllRepoRequest())
      .unwrap()
      .then((res) => {
        dispatch(setLoading(false));
        if (labError) {
          Toast("error", "Something went wrong", 2);
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
    if (
      location.pathname.includes("staff") ||
      location.pathname.includes("student") ||
      location.pathname.includes("performance")
    ) {
      setSubmMenu(["1"]);
    } else if (
      location.pathname.includes("add-repository") ||
      location.pathname.includes("add-lab") ||
      location.pathname.includes("all-lab") ||
      location.pathname.includes("lab-configuration")
    ) {
      setSubmMenu(["2"]);
    } else if (
      location.pathname.includes("user_activity_overview") ||
      location.pathname.includes("user_session_details") ||
      location.pathname.includes("feedback_summary")||
      location.pathname.includes("user_progress_tracking")||
      location.pathname.includes("user_resource_utilization")
    ) {
      setSubmMenu(["4"]);
    } else if (location.pathname.includes("dashboard")) {
      setSubmMenu([]);
    } else if (location.pathname.includes("lab-repository")) {
      setSubmMenu(["2", "3"]);
    } else {
      setSubmMenu([]);
    }
    // console.log(location.pathname);
  }, [location, current, navigate]);

  const SiderItems = [
    {
      label: GlobalLabels.DASHBOARD,
      key: "/admin/dashboard",
      icon: <MdOutlineDashboard className="sidebarIcon" />,
    },
    {
      label: GlobalLabels.USER,
      key: "1",
      icon: <FaRegUserCircle className="sidebarIcon" />,
      children: [
        {
          type: "group",
          children: [
            {
              label: GlobalLabels.STAFF,
              key: "/admin/staff",
              icon: <MdOutlineAccountBox className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.STUDENT,
              key: "/admin/student",
              icon: <MdSupervisorAccount className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.LAB_PERFORMANCE,
              key: "/admin/student/performance",
              icon: <BiFolderOpen className="sidebarIcon" />,
            },
          ],
        },
      ],
    },

    {
      label: GlobalLabels.LABS,
      key: "2",
      icon: <AiOutlineExperiment className="sidebarIcon" />,
      children: [
        {
          type: "group",
          children: [
            {
              label: GlobalLabels.ADD_REPOSITORY,
              key: "/admin/add-repository",
              icon: <MdOutlineLibraryAdd className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.ADD_LAB,
              key: "/admin/add-lab",
              icon: <BiBookAdd className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.ALL_LABS,
              key: "/admin/all-lab",
              icon: <BiFolderOpen className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.LAB_CONFIG,
              key: "/admin/lab-configuration",
              icon: <BiFolderOpen className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.LAB_REPO,
              key: "3",
              icon: <GoRepo className="sidebarIcon" />,
              children: [
                {
                  type: "group",
                  children: labCategory?.map((lab) => {
                    return {
                      label: lab.categoryname,
                      key: lab.siderPathKey,
                    };
                  }),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: GlobalLabels.REPORTS,
      key: "4",
      icon: <FaRegUserCircle className="sidebarIcon" />,
      children: [
        {
          type: "group",
          children: [
            {
              label: GlobalLabels.REPORTS_USER_ACTIVITY_OVERVIEW,
              key: "/admin/reports/user_activity_overview",
              icon: <MdOutlineAccountBox className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.REPORTS_USER_SESSION_DETAILS,
              key: "/admin/reports/user_session_details",
              icon: <MdOutlineAccountBox className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.REPORTS_FEEDBACK_SUMMARY,
              key: "/admin/reports/feedback_summary",
              icon: <MdOutlineAccountBox className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.REPORTS_USER_PROCESS_TRACKING,
              key: "/admin/reports/user_progress_tracking",
              icon: <MdOutlineAccountBox className="sidebarIcon" />,
            },
            {
              label: GlobalLabels.REPORTS_RESOURCE_UTILIZATION,
              key: "/admin/reports/user_resource_utilization",
              icon: <MdOutlineAccountBox className="sidebarIcon" />,
            },
          ],
        },
        // {
        //   type: "group",
        //   children: [
        //     {
        //       label: GlobalLabels.REPORTS_USER_SESSION_DETAILS,
        //       key: "/admin/reports/user_session_details",
        //       icon: <MdOutlineAccountBox className="sidebarIcon" />,
        //     },
        //   ],
        // },
      ],
    },
    {
      label: GlobalLabels.SUPPORT,
      key: "/admin/support",
      icon: <BiSupport className="sidebarIcon" />,
    },
  ];

  const handleNavClick = (e) => {
    navigate(`${e.key}`, { replace: true });
    setCurrent(e.key);
  };
  const handleLogout = () => {
    navigate("/admin/login");
    dispatch(setDefaultState());
    Toast("success", "See you around");
    localStorage.removeItem("adminEmail");
  };

  const selectedKeys = () => {
    const matchingItem = labCategory?.find((item) =>
      current.includes(item.routePath)
    );
    // console.log("🚀 ~ selectedKeys ~ matchingItem:", matchingItem);
    // console.log("🚀 ~ selectedKeys ~ matchingItem:", current);

    return matchingItem ? [matchingItem.siderPathKey] : [current];
  };
  // console.log("🚀 ~ SideBar ~ openKeys:", subMenu);
  return (
    <React.Fragment>
      {screens.lg && (
        <Sider
          onCollapse={() => dispatch(setCollapse(!siderCollapse))}
          collapsedWidth="0"
          width={280}
          collapsible
          collapsed={siderCollapse}
          style={{ minHeight: "100vh" }}
        >
          <React.Fragment>
            <div className="flex gap-20 align-items-center">
              <img
                src={darkLogo}
                className="pointer margin-top"
                width={200}
                alt="logoimg"
                onClick={() => navigate("/admin/dashboard")}
              />
            </div>
          </React.Fragment>

          {siderCollapse ? null : (
            <div className="flex logo-container">
              <Text style={{ color: "white" }}>Logged in @ </Text>
              <Text style={{ color: "#9ad5f0" }}>
                {userLogin.email.substring(0, userLogin.email.lastIndexOf("@"))}
              </Text>
            </div>
          )}

          <Menu
            theme="dark"
            onClick={handleNavClick}
            mode="inline"
            items={SiderItems}
            selectedKeys={selectedKeys()}
            onOpenChange={(openKeys) => {
              setSubmMenu(openKeys);
            }}
            openKeys={subMenu}
          />

          <div className="menuFotter">
            <Buttons
              title={
                <div className="modelContents">
                  <RiLogoutCircleRLine />
                  {siderCollapse ? null : <span>{GlobalLabels.LOGOUT}</span>}
                </div>
              }
              size="large"
              type="default"
              htmlType="submit"
              colorPrimaryHover="#0b4a72"
              onClick={() => setlogoutConfimmodalOpen(true)}
            />

            <ModalDisplay
              openModal={logoutConfimmodalOpen}
              closeModal={() =>
                setlogoutConfimmodalOpen(!logoutConfimmodalOpen)
              }
              footerDisplay={false}
              modalContentJSX={() => (
                <React.Fragment>
                  <Text>Are you sure do you want to Logout ?</Text>

                  <div className="menubarRightButtons deletemodelFooter">
                    <ConfigProvider
                      theme={{ token: { colorPrimaryHover: "red" } }}
                    >
                      <Button
                        type="default"
                        onClick={() => setlogoutConfimmodalOpen(false)}
                      >
                        <div className="modelContents">
                          <RxCrossCircled size={18} />
                          <span>NO</span>
                        </div>
                      </Button>
                    </ConfigProvider>

                    <ConfigProvider
                      theme={{ token: { colorPrimaryHover: "red" } }}
                    >
                      <Button
                        type="primary"
                        danger
                        onClick={handleLogout}
                        htmlType="submit"
                      >
                        <div className="modelContents">
                          <span>YES</span>
                          <RxCheckCircled size={18} />
                        </div>
                      </Button>
                    </ConfigProvider>
                  </div>
                </React.Fragment>
              )}
            />
          </div>
        </Sider>
      )}

      {/* Responsive Drawer for md, sm, xs screens*/}
      <Drawer
        style={{ backgroundColor: "#001529" }}
        // style={{ backgroundColor: "#15133C" }}
        width={250}
        title={null}
        extra={
          <Row justify={"space-around"}>
            <Col>
              <CgClose
                className="pointer"
                color="white"
                size={25}
                onClick={() => dispatch(setDrawer(!drawerCollapse))}
              />
            </Col>
            <Col>
              <img
                src={darkLogo}
                className="pointer"
                width={120}
                alt="logoimg"
                onClick={() => navigate("/admin/dashboard")}
              />
            </Col>
          </Row>
        }
        footer={
          <Row justify={"center"}>
            <Col>
              <Divider dashed style={{ color: "white" }} />
            </Col>
            <Col>
              <Buttons
                type="primary"
                colorPrimary="#73777B"
                colorPrimaryHover="#EC994B"
                onClick={() => {
                  setlogoutConfimmodalOpen(true);
                  dispatch(setDrawer(!drawerCollapse));
                }}
                title={GlobalLabels.LOGOUT}
              />

              <ModalDisplay
                openModal={logoutConfimmodalOpen}
                closeModal={() =>
                  setlogoutConfimmodalOpen(!logoutConfimmodalOpen)
                }
                footerDisplay={false}
                modalContentJSX={() => (
                  <React.Fragment>
                    <Text>Are you sure do you want to Logout ?</Text>

                    <div className="menubarRightButtons deletemodelFooter">
                      <ConfigProvider
                        theme={{ token: { colorPrimaryHover: "red" } }}
                      >
                        <Button
                          type="default"
                          onClick={() => setlogoutConfimmodalOpen(false)}
                        >
                          <div className="modelContents">
                            <RxCrossCircled size={18} />
                            <span>NO</span>
                          </div>
                        </Button>
                      </ConfigProvider>

                      <ConfigProvider
                        theme={{ token: { colorPrimaryHover: "red" } }}
                      >
                        <Button
                          type="primary"
                          danger
                          onClick={handleLogout}
                          htmlType="submit"
                        >
                          <div className="modelContents">
                            <span>YES</span>
                            <RxCheckCircled size={18} />
                          </div>
                        </Button>
                      </ConfigProvider>
                    </div>
                  </React.Fragment>
                )}
              />
            </Col>
          </Row>
        }
        placement="right"
        open={drawerCollapse}
      >
        <div className="flex">
          <Text style={{ color: "white" }}>Logged in @ </Text>
          <Text style={{ color: "#9ad5f0" }}>
            {userLogin.email.substring(0, userLogin.email.lastIndexOf("@"))}
          </Text>
        </div>
        <Menu
          theme="dark"
          onClick={handleNavClick}
          mode="inline"
          items={SiderItems}
          defaultSelectedKeys={current}
          defaultOpenKeys={subMenu}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default SideBar;
