import { createSlice } from "@reduxjs/toolkit";
import { adminloginRequest, userLoginRequest, userLMSLoginRequest } from "../service/UserService";

const state = {
  credentials: {},
  isFetching: false,
  error: "",
  isAuth: false,
};

const UserSlice = createSlice({
  name: "user",
  initialState: state,
  reducers: {
    setDefaultState: (state) => {
      state.credentials = {
        labs: {},
      };
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminloginRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(adminloginRequest.fulfilled, (state, action) => {
      state.isFetching = false;
      state.credentials = action.payload.success
        ? action.payload.data
        : { role: null };
      state.isAuth = action.payload.data?.token ? true : false;
    });
    builder.addCase(adminloginRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.isAuth = false;
      state.error = action.payload.data.message;
    });

    builder.addCase(userLoginRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(userLoginRequest.fulfilled, (state, action) => {
      state.isFetching = false;
      state.credentials = action.payload.success
        ? action.payload.data
        : { role: null };
      state.isAuth = action.payload.data?.token ? true : false;
    });
    builder.addCase(userLoginRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.isAuth = false;
      state.error = action.payload.data.message;
    });

    builder.addCase(userLMSLoginRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(userLMSLoginRequest.fulfilled, (state, action) => {
      state.isFetching = false;
      state.credentials = action.payload.success
        ? action.payload.data
        : { role: null };
      state.isAuth = action.payload.data?.token ? true : false;
    });
    builder.addCase(userLMSLoginRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.isAuth = false;
      state.error = action.payload.data.message;
    });
  },
});

export const { setAdminDetails, setDefaultState } = UserSlice.actions;
export default UserSlice.reducer;
