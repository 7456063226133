import {
  HomeOutlined,
  MenuOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, ConfigProvider, Menu, Modal, Row, Tabs } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useEffect, useMemo, useState } from "react";
import { BiLogInCircle } from "react-icons/bi";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router";
import darkLogo from "../../assets/img/logo/SkillsDA Logo with Text - White Colour.png";
import { GlobalLabels, TopNavItems } from "../../utils/constants/common";
import Login from "../login";
const TopNavBar = ({ isLoggedIn }, ...rest) => {
  const position = ["left", "right"];
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState("/");
  const location = useLocation();
  const { pathname } = location;

  const OperationsSlot = {
    left: (
      <img
        src={darkLogo}
        className="homeLogo"
        alt="logoimg"
        onClick={() => navigate(`/`)}
      />
    ),
    right: pathname !== "/admin/login" && (
      <div className="menubarRightButtons">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#0b4a72",
            },
          }}
        >
          <Button type="primary" onClick={() => setLoginModal(!loginModal)}>
            <div className="modelContents">
              <BiLogInCircle size={"20"} />
              <span>{GlobalLabels.LOGIN}</span>
            </div>
          </Button>
        </ConfigProvider>

        <ConfigProvider>
          <Button type="default" onClick={() => navigate("/register")}>
            <div className="modelContents">
              <span>{GlobalLabels.REGISTRATION}</span>
              <HiOutlinePencilSquare size={"20"} />
            </div>
          </Button>
        </ConfigProvider>
      </div>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const tabRoutes = ["/", "/help-desk", "/lab-repository"];
  useEffect(() => {
    if (!isLoggedIn && !tabRoutes.includes(pathname)) {
      setDefaultActiveKey("0");
    } else {
      setDefaultActiveKey(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, defaultActiveKey, isLoggedIn]);

  const tabOnChange = (key) => {
    setDefaultActiveKey(key);
    navigate(`${key}`);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMenu = (text) => {
    if (text === GlobalLabels.LOGIN) {
      setLoginModal(!loginModal);
    } else if (text === GlobalLabels.REGISTRATION) {
      navigate("/register");
    }
    setIsMenuOpen(false);
  };
  const updateSize = () => {
    const windowSize = Math.max(window.innerWidth);
    if (windowSize >= 768) {
      setIsMenuOpen(false);
    }
  };

  const menubarImage = () => {
    navigate(`/`);
  };

  const handleNavClick = (e) => {
    navigate(`${e.key}`, { replace: true });
    setIsMenuOpen(!isMenuOpen);
  };

  const SiderItems = [
    {
      label: GlobalLabels.HOME,
      key: "/",
      icon: <HomeOutlined className="sidebarIcon" />,
    },
    {
      label: GlobalLabels.HELP_DESK,
      key: "/help-desk",
      icon: <UserOutlined className="sidebarIcon" />,
    },
    {
      label: GlobalLabels.LAB_REPO,
      key: "/lab-repository",
      icon: <SettingOutlined className="sidebarIcon" />,
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", updateSize);
  }, []);
  return isLoggedIn ? null : (
    <React.Fragment>
      <Row justify={"center"} align="middle">
        <Col xs={isLoggedIn ? 22 : 0} md={22}>
          <Tabs
            className="tab-color"
            tabBarGutter={55}
            defaultActiveKey={"/"}
            activeKey={defaultActiveKey}
            tabBarExtraContent={slot}
            items={!isLoggedIn && TopNavItems}
            onChange={tabOnChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={isLoggedIn ? 0 : 2} md={0}>
          <img
            src={darkLogo}
            className="homeLogo"
            alt="logoimg"
            onClick={menubarImage}
          />
        </Col>
        <Col xs={isLoggedIn ? 0 : 22} md={0}>
          <div style={{ marginTop: "8px", float: "right" }}>
            <MenuOutlined
              onClick={handleMenuClick}
              style={{ color: "white", fontSize: "20px", marginTop: "8px" }}
            />
          </div>
        </Col>
      </Row>

      {/* RESPONSIVE HAMBURG MENU OPEN */}
      {isMenuOpen && (
        <Sider className="menu" width={280} style={{ minHeight: "100vh" }}>
          <Menu
            className="padding-left"
            theme="dark"
            onClick={handleNavClick}
            mode="inline"
            items={SiderItems}
            selectedKeys={location.pathname}
          />
          <Row className="footer">
            <Row
              style={{ marginLeft: "20px" }}
              className=" margin-top padding-left"
            >
              <Button
                type="primary"
                onClick={() => handleMenu(GlobalLabels.LOGIN)}
              >
                <Row className="modelContents ">
                  <BiLogInCircle size={"20"} />
                  <span>{GlobalLabels.LOGIN}</span>
                </Row>
              </Button>
            </Row>
            <Row style={{ marginLeft: "10px" }} className="margin-top ">
              <Button
                type="default"
                onClick={() => handleMenu(GlobalLabels.REGISTRATION)}
              >
                <Row className="modelContents">
                  <span>{GlobalLabels.REGISTRATION}</span>
                  <HiOutlinePencilSquare size={"20"} />
                </Row>
              </Button>
            </Row>
          </Row>
        </Sider>
      )}

      {/* Login Modal */}
      <Modal
        centered
        open={loginModal}
        onCancel={() => setLoginModal(false)}
        footer={null}
        closable={false}
      >
        <Login />
      </Modal>
    </React.Fragment>
  );
};

export default TopNavBar;
