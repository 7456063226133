import { createSlice } from "@reduxjs/toolkit";
import {
    allStaffsRequest,
    createStaffRequest,
    deleteStaff,
    getStaffRequest,
    updateStaffRequest,
} from "../service/StaffService";

const state = {
  isFetching: false,
  error: false,
  staffDetails: [],
  uniqueStaff: {},
};

const StaffRegistSlice = createSlice({
  name: "staff",
  initialState: state,
  reducers: {
    setStaffDetails: (state, action) => {
      state.staffDetails = action.payload;
    },
    setUniqueStaff: (state, action) => {
      state.uniqueStaff = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get all staff
    builder.addCase(allStaffsRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(allStaffsRequest.fulfilled, (state, action) => {
      const staffs = action.payload?.users;
      state.isFetching = false;
      state.staffDetails = staffs ? staffs : [];
    });
    builder.addCase(allStaffsRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    //Create Staff
    builder.addCase(createStaffRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(createStaffRequest.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(createStaffRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // get unique staff
    builder.addCase(getStaffRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getStaffRequest.fulfilled, (state, action) => {
      const user = action.payload?.user;
      state.isFetching = false;
      state.uniqueStaff = user ? user : {};
    });
    builder.addCase(getStaffRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // update staff
    builder.addCase(updateStaffRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(updateStaffRequest.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(updateStaffRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // Delete staff
    builder.addCase(deleteStaff.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(deleteStaff.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(deleteStaff.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });
  },
});

export const { setStaffDetails, setUniqueStaff } = StaffRegistSlice.actions;
export default StaffRegistSlice.reducer;
