import { Button, ConfigProvider } from "antd";

const Buttons = ({
  icon = "",
  style = {},
  block = false,
  htmlType,
  danger = false,
  size = "large",
  type = "default",
  disabled = false,
  colorPrimary = "",
  onClick,
  colorPrimaryHover = "",
  title = "Default Title",
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryHover: colorPrimaryHover,
          colorPrimary: colorPrimary,
        },
      }}
    >
      <Button
        type={type}
        size={size}
        icon={icon}
        block={block}
        style={style}
        danger={danger}
        onClick={onClick}
        disabled={disabled}
        htmlType={htmlType}
      >
        {title}
      </Button>
    </ConfigProvider>
  );
};

export default Buttons;
