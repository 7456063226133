import { FloatButton } from "antd";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import LoadingWrapper from "./components/loading-wrapper";
import store, { persistor } from "./redux/store";
import AppRoutes from "./routes";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoadingWrapper>
            <Router>
              <AppRoutes />
              <FloatButton.BackTop
                icon={
                  <IoIosArrowDropupCircle
                    size={28}
                    style={{ marginLeft: -5, color: "#0b4a72" }}
                  />
                }
              />
            </Router>
          </LoadingWrapper>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
