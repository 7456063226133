import { createSlice } from "@reduxjs/toolkit";
import { assignConnectionInGuacamole, createConnectionRequestInGuacamole, createUserRequestInGuacamole, getInstanceCountsRequest, linuxStartInstanceRequest, serverSpaceCreateInstanceRequest, serverSpaceGetInstanceRequest, sshConnectionRequest, WindowsStartInstanceRequest } from "../service/InstanceService";

const state = {
    error: "",
    stats: {},
    victim: {},
    instance: {},
    isFetching: false,
    isVictimAlive: false,
    isInstanceAlive: false,
};

const instanceSlice = createSlice({
    name: 'instance',
    initialState: state,
    reducers: {
        setDefaultInstance: (state, action) => {
            state.instance = {};
            state.isInstanceAlive = false;
        },
        resetInstanceData: (state, action) => {
            const data = {
                guacIdentifier: state.instance.guacIdentifier,
                guacPassword: state.instance.guacPassword,
                guacUserName: state.instance.guacUserName
            }
            state.instance = data;
        },
        setDefaultVictim: (state, action) => {
            state.victim = {};
            state.isVictimAlive = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(linuxStartInstanceRequest.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(linuxStartInstanceRequest.fulfilled, (state, action) => {
            state.isFetching = false;
            let data = action?.payload.data ? {
                instanceid: action.payload.data.instanceid,
                pageLink: window.location.href
            }
                : {};
            if (action.payload?.isStartVictim) {
                state.victim = data;
                state.isVictimAlive = action?.payload?.data.instanceid ? true : false;
            } else {
                state.instance = data;
                state.isInstanceAlive = action?.payload?.data.instanceid ? true : false;
            }
        })
        builder.addCase(linuxStartInstanceRequest.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.payload.data.error;
        })
        builder.addCase(WindowsStartInstanceRequest.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(WindowsStartInstanceRequest.fulfilled, (state, action) => {
            state.isFetching = false;
            let data = action?.payload?.data ?
                {
                    instanceid: action.payload.data.instanceid,
                    publicip: action.payload.data.publicip,
                    pageLink: window.location.href
                }
                : { ...state.instance };
            if (action.payload?.isStartVictim) {
                state.victim = data;
                state.isVictimAlive = action?.payload?.data?.instanceid ? true : false;
            } else {
                state.instance = data;
                state.isInstanceAlive = action?.payload?.data?.instanceid ? true : false;
            }
        })
        builder.addCase(WindowsStartInstanceRequest.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.payload.data.error;
        })

        // CreatingConnectionDetails
        builder.addCase(createConnectionRequestInGuacamole.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(createConnectionRequestInGuacamole.fulfilled, (state, action) => {
            state.isFetching = false;
            state.instance = action.payload ? {
                ...state.instance,
                guacIdentifier: action.payload.data.identifier
            } : {
                ...state.instance
            }
        })
        builder.addCase(createConnectionRequestInGuacamole.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.payload.data.error;
        })

        // GuacamoleUserDetails
        builder.addCase(createUserRequestInGuacamole.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(createUserRequestInGuacamole.fulfilled, (state, action) => {
            // debugger
            state.isFetching = false;
            state.instance = action.payload ? {
                ...state.instance,
                guacUserName: action.payload.data.username,
                guacPassword: action.payload.data.password
                // url: process.env.REACT_APP_GUACAMOLE_BASE_URL
            } : {
                ...state.instance
            }
        })
        builder.addCase(createUserRequestInGuacamole.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.payload.data.error;
        })

        //Assigning connection to a user
        builder.addCase(assignConnectionInGuacamole.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(assignConnectionInGuacamole.fulfilled, (state, action) => {
            state.isFetching = false;
            state.instance = action.payload?.status === 204 ? {
                ...state.instance,
                url: process.env.REACT_APP_GUACAMOLE_BASE_URL
            } : {
                ...state.instance
            }
        })
        builder.addCase(assignConnectionInGuacamole.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.payload.data.error;
        })

        // Server Space
        // create Instance
        builder.addCase(serverSpaceCreateInstanceRequest.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(serverSpaceCreateInstanceRequest.fulfilled, (state, action) => {
            state.isFetching = false;
            let data = action?.payload?.data ?
                {
                    task_id: action.payload.data?.data?.task_id,
                }
                : {};
            if (action.payload.isStartVictim) {
                state.victim = data;
            } else {
                state.instance = data;
            }
        })
        builder.addCase(serverSpaceCreateInstanceRequest.rejected, (state, action) => {
            state.isFetching = false;
            state.error = "Instance Creation Error";
        })

        // Get Instance
        builder.addCase(serverSpaceGetInstanceRequest.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(serverSpaceGetInstanceRequest.fulfilled, (state, action) => {
            state.isFetching = false;
            let data = action?.payload?.data ?
                {
                    task_id: action.payload.data.data.id,
                    server_id: action.payload.data.data.id,
                    username: action.payload.data.data.login,
                    password: action.payload.data.data.password,
                    publicip: action.payload.data.data.nics[0].ip_address,
                    pageLink: window.location.href
                }
                : {};
            if (action?.payload?.data.url) {
                if (action.payload.saveIn === "instance") {
                    state.instance = { ...data, url: action?.payload?.url }
                    state.isInstanceAlive = action?.payload?.url ? true : false;
                } else {
                    state.victim = { ...data, url: action?.payload?.url }
                    state.isVictimAlive = action?.payload?.url ? true : false;
                }
            }
        })
        builder.addCase(serverSpaceGetInstanceRequest.rejected, (state, action) => {
            state.isFetching = false;
            state.error = "Get Instance error";
        })

        // SSh Connection
        builder.addCase(sshConnectionRequest.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(sshConnectionRequest.fulfilled, (state, action) => {
            state.isFetching = false;
            let data = {
                url: action?.payload?.url,
                pageLink: window.location.href
            }
            if (action?.payload?.saveIn === "instance") {
                state.instance = {
                    ...state.instance,
                    ...data
                }
                state.isInstanceAlive = action?.payload?.url ? true : false;
            } else {
                state.victim = {
                    ...state.victim,
                    ...data
                }
                state.isVictimAlive = action?.payload?.url ? true : false;
            }
        })
        builder.addCase(sshConnectionRequest.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.payload.data;
        })

        // Counts
        builder.addCase(getInstanceCountsRequest.pending, (state, action) => {
            state.isFetching = true;
        })
        builder.addCase(getInstanceCountsRequest.fulfilled, (state, action) => {
            state.isFetching = false;
            state.stats = action.payload?.data ? {
                ...state.stats,
                aws: action.payload.data.awsCounts,
                serverSpace: action.payload.data.serverSpaceCounts,
            } : {}
        })
        builder.addCase(getInstanceCountsRequest.rejected, (state, action) => {
            state.isFetching = false;
            state.error = "Fetch Failed";
        })
    }
})

export const { setDefaultInstance, setDefaultVictim, resetInstanceData } = instanceSlice.actions
export default instanceSlice.reducer;
