import { createSlice } from "@reduxjs/toolkit";

const state = {
  loading: false,
  siderCollapse: false,
  drawerCollapse: false,
};

const constantSlice = createSlice({
  name: "labs",
  initialState: state,
  reducers: {
    setCollapse: (state, action) => {
      state.siderCollapse = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setDrawer: (state, action) => {
      state.drawerCollapse = action.payload;
    },
  },
});

export const { setCollapse, setLoading, setDrawer } = constantSlice.actions;
export default constantSlice.reducer;
