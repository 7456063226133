import { lazy } from "react";

export const AdminPrivateRoutes = [
  {
    name: "Dashboard",
    path: "/",
    component: lazy(() => import("../../containers/admin-panel/dashboard")),
  },
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    component: lazy(() => import("../../containers/admin-panel/dashboard")),
  },
  {
    name: "Start Lab",
    path: "/start-lab/:labId",
    component: lazy(() => import("../../containers/start-lab")),
  },
  {
    name: "Add Lab",
    path: "/admin/add-lab",
    component: lazy(() => import("../../containers/admin-panel/add-lab")),
  },
  {
    name: "All Lab",
    path: "/admin/all-lab",
    component: lazy(() => import("../../containers/admin-panel/all-lab")),
  },
  {
    name: "Lab Configuration",
    path: "/admin/lab-configuration",
    component: lazy(() => import("../../containers/admin-panel/lab-config")),
  },
  {
    name: "MCQ",
    path: "/admin/lab-configuration/mcq/:labId",
    component: lazy(() =>
      import("../../containers/admin-panel/lab-config/mcq")
    ),
  },
  {
    name: "Edit Lab",
    path: "/admin/edit-lab/:labId",
    component: lazy(() => import("../../containers/admin-panel/add-lab")),
  },
  {
    name: "Add repository",
    path: "/admin/add-repository",
    component: lazy(() =>
      import("../../containers/admin-panel/add-repository")
    ),
  },
  {
    name: "Labs",
    path: "/admin/lab-repository/:labCategory/:categoryId",
    component: lazy(() => import("../../containers/lab-repository/all-Labs")),
  },
  {
    name: "Lab Display",
    path: "/admin/:labTitle/:labId",
    component: lazy(() =>
      import("../../containers/lab-repository/lab-display")
    ),
  },
  {
    name: "Staff",
    path: "/admin/staff",
    component: lazy(() => import("../../containers/admin-panel/staff")),
  },
  {
    name: "Add Staff",
    path: "/admin/staff/new",
    component: lazy(() => import("../../containers/register")),
  },
  {
    name: "Edit Staff",
    path: "/admin/staff/edit/:userId",
    component: lazy(() => import("../../containers/register")),
  },
  {
    name: "Student",
    path: "/admin/student",
    component: lazy(() => import("../../containers/admin-panel/student")),
  },
  {
    name: "Add Student",
    path: "/admin/student/new",
    component: lazy(() => import("../../containers/register")),
  },
  {
    name: "Edit Student",
    path: "/admin/student/edit/:userId",
    component: lazy(() => import("../../containers/register")),
  },
  {
    name: "Assign Lab To Student",
    path: "/admin/assign-lab-to-student/:studentId",
    component: lazy(() =>
      import("../../containers/admin-panel/student/assign-lab")
    ),
  },
  {
    name: "Student Performance",
    path: "/admin/student/performance",
    component: lazy(() => import("../../containers/admin-panel/performance")),
  },
  {
    name: "User Activity Overview",
    path: "/admin/reports/user_activity_overview",
    component: lazy(() =>
      import("../../containers/admin-panel/reports/UserActivityOverview")
    ),
  },
  {
    name: "Feedback Summary",
    path: "/admin/reports/feedback_summary",
    component: lazy(() =>
      import("../../containers/admin-panel/reports/FeedbackSummary")
    ),
  },
  {
    name: "User Session Details",
    path: "/admin/reports/user_session_details",
    component: lazy(() =>
      import("../../containers/admin-panel/reports/UserSessionDetails")
    ),
  },
  {
    name: "User Process tracking",
    path: "/admin/reports/user_progress_tracking",
    component: lazy(() =>
      import("../../containers/admin-panel/reports/UserProgressTracking")
    ),
  },
  {
    name: "User Process tracking",
    path: "/admin/reports/user_resource_utilization",
    component: lazy(() =>
      import("../../containers/admin-panel/reports/UserResourceUtilizationReport")
    ),
  },
  {
    name: "Merge Lab",
    path: "/admin/merge-lab/:studentId",
    component: lazy(() =>
      import("../../containers/admin-panel/student/merge-lab")
    ),
  },
  {
    name: "Support ",
    path: "/admin/support",
    component: lazy(() => import("../../containers/support")),
  },
  {
    name: "Page 404",
    path: "*",
    component: lazy(() => import("../../components/404")),
  },
];

export const StudentPrivateRoutes = [
  {
    name: "Student Home",
    path: "/",
    component: lazy(() => import("../../containers/student/home")),
  },
  {
    name: "Student Home",
    path: "/user/",
    component: lazy(() => import("../../containers/student/home")),
  },
  {
    name: "Student Lab Display",
    path: "/user/:labId",
    component: lazy(() =>
      import("../../containers/lab-repository/lab-display")
    ),
  },
  {
    name: "Start Lab",
    path: "/start-lab/:labId",
    component: lazy(() => import("../../containers/start-lab")),
  },
  {
    name: "Page 404",
    path: "*",
    component: lazy(() => import("../../components/404")),
  },
];

export const publicRoutes = [
  {
    name: "Home",
    path: "/",
    component: lazy(() => import("../../containers/home")),
  },
  {
    name: "Help Desk",
    path: "/help-desk",
    component: lazy(() => import("../../containers/help-desk")),
  },
  {
    name: "About Us",
    path: "/about-us",
    component: lazy(() => import("../../containers/about-us")),
  },
  {
    name: "Lab Repository",
    path: "/lab-repository",
    component: lazy(() => import("../../containers/lab-repository")),
  },
  {
    name: "Lab",
    path: "/lab-repository/:labCategory/:categoryId",
    component: lazy(() =>
      import("../../containers/lab-repository/public-lab-display")
    ),
  },
  {
    name: "Register",
    path: "/register",
    component: lazy(() => import("../../containers/register")),
  },
  {
    name: "Admin Login",
    path: "/admin/login",
    component: lazy(() => import("../../containers/admin-panel/admin-login")),
  },
  {
    name: "Reset Password",
    path: "/reset-password/:id/:token",
    component: lazy(() => import("../../containers/student/reset-password")),
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: lazy(() => import("../../containers/student/forgot-password")),
  },
  {
    name: "Loading",
    path: "/:token",
    component: lazy(() => import("../../components/loading")),
  },
  {
    name: "Student Lab Display",
    path: "/lab",
    component: lazy(() => import("../../containers/user-login-via-lms")),
  },
  {
    name: "Page 404",
    path: "*",
    component: lazy(() => import("../../components/404")),
  },
];
