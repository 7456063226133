import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Grid,
  Menu,
  Row,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { setDefaultState } from "../../../redux/slice/UserSlice";
import ModalDisplay from "../../../components/modal";
import Toast from "../../../components/toast";
import { Capitalaize, GlobalLabels } from "../../../utils/constants/common";
import Logo from "../../../assets/img/logo/SkillsDA Logo with Text - Blue Colour.png";
import darkLogo from "../../../assets/img/logo/SkillsDA Logo with Text - White Colour.png";
import studentprofile from "../../../assets/img/studentprofile.png";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRLine, RiMore2Fill } from "react-icons/ri";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";

const { useBreakpoint } = Grid;

const StudentWrapper = ({ children }) => {
  const screens = useBreakpoint();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const userLoggedin = useSelector((state) => state.user.credentials);
  const [logoutConfimmodalOpen, setlogoutConfimmodalOpen] = useState(false);
  const [drawerOpen, setdrawerOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    localStorage.removeItem("rememberMe");
    dispatch(setDefaultState());
    Toast("success", "See you around");
    navigate("/");
  };

  let data = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => (
      <Tag color="blue" key={screen[0]}>
        {screen[0]}
      </Tag>
    ));

  let breakpoint = data.map((data) => data.key);

  const logoutConfimmodal = () => (
    <React.Fragment>
      <Text>Are you sure do you want to Logout ?</Text>
      <div className="menubarRightButtons deletemodelFooter">
        <ConfigProvider theme={{ token: { colorPrimaryHover: "red" } }}>
          <Button
            type="default"
            onClick={() => setlogoutConfimmodalOpen(false)}
          >
            <div className="modelContents">
              <RxCrossCircled size={18} />
              <span>NO</span>
            </div>
          </Button>
        </ConfigProvider>

        <ConfigProvider theme={{ token: { colorPrimaryHover: "red" } }}>
          <Button
            type="primary"
            danger
            onClick={handleLogout}
            htmlType="submit"
          >
            <div className="modelContents">
              <span>YES</span>
              <RxCheckCircled size={18} />
            </div>
          </Button>
        </ConfigProvider>
      </div>
    </React.Fragment>
  );

  const items = [
    {
      key: "1",
      label: (
        <div className="modelContents" onClick={() => setdrawerOpen(true)}>
          <CgProfile />
          <span>Profile</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="modelContents"
          onClick={() => setlogoutConfimmodalOpen(true)}
        >
          <RiLogoutCircleRLine />
          <span>Logout</span>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row justify={"center"}>
        {!location.pathname.includes("start-lab") && (
          <Col xs={24}>
            <Menu theme="dark">
              <Row align={"middle"} justify={"center"}>
                <Col xs={20}>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col xs={8}>
                      <img
                        alt="logo"
                        src={darkLogo}
                        onClick={() => navigate("/user")}
                        width={180}
                        style={{ margin: "10px 0" }}
                        className="pointer"
                      />
                    </Col>

                    {/* Student Profile */}
                    <Row align={"middle"} justify={"space-between"}>
                      <Col md={11} xs={0}>
                        <ConfigProvider
                          theme={{ token: { colorPrimary: "#0b4a72" } }}
                        >
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => setdrawerOpen(true)}
                          >
                            <div className="modelContents">
                              <CgProfile />
                              <span>Profile</span>
                            </div>
                          </Button>
                        </ConfigProvider>
                      </Col>
                      <Col md={11} xs={0}>
                        <Button
                          size="large"
                          type="primary"
                          danger
                          onClick={() => setlogoutConfimmodalOpen(true)}
                        >
                          <div className="modelContents">
                            <RiLogoutCircleRLine />
                            <span>Logout</span>
                          </div>
                        </Button>
                      </Col>
                    </Row>

                    <Col md={0} xs={2}>
                      <Dropdown
                        menu={{ items }}
                        placement="bottom"
                        arrow
                        trigger={["click"]}
                      >
                        <RiMore2Fill size={30} />
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Menu>
          </Col>
        )}
        <Col
          md={!location.pathname.includes("start-lab") ? 20 : 24}
          xs={!location.pathname.includes("start-lab") ? 20 : 22}
        >
          {children}
        </Col>
      </Row>

      <Drawer
        title={
          <Row justify={"space-between"} align="middle">
            <Col>
              <img
                src={Logo}
                width={180}
                style={{ marginLeft: 15 }}
                alt="darkLogo"
              />
            </Col>
            <Col>
              <Text
                style={{
                  fontSize: 28,
                  color: "#0b4a72",
                  marginRight: 20,
                }}
              >
                {`${GlobalLabels.STUDENT} ${GlobalLabels.INFORMATION}`}
              </Text>
            </Col>
          </Row>
        }
        placement="left"
        onClose={() => setdrawerOpen(false)}
        width={
          breakpoint.toString() === "sm"
            ? 420
            : breakpoint.toString() === "xs"
            ? 280
            : 720
        }
        open={drawerOpen}
      >
        <Row justify={"center"} align={"middle"}>
          <Col>
            <img
              src={studentprofile}
              width={
                breakpoint.toString() === "sm"
                  ? 250
                  : breakpoint.toString() === "xs"
                  ? 250
                  : 400
              }
              alt="studentprofile"
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <Row justify={"center"} style={{ marginBottom: 25 }}>
            <Col span={24}>
              <Title>
                {`${Capitalaize(userLoggedin.firstname)} ${
                  userLoggedin.lastname
                }`}
              </Title>
            </Col>
          </Row>
        </Divider>

        <Row justify={"center"}>
          <Col span={1} />
          <Col md={14} xs={23} style={{ marginBottom: 25 }}>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}>
              {GlobalLabels.EMAIL}
            </Text>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}> : </Text>
            <Text>{userLoggedin.email}</Text>
          </Col>
          <Col span={1} />
          <Col md={8} xs={23} style={{ marginBottom: 25 }}>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}>
              {GlobalLabels.PHONE}
            </Text>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}> : </Text>
            <Text>{userLoggedin.phonenumber}</Text>
          </Col>
        </Row>

        <Row justify={"center"}>
          <Col span={1} />
          <Col md={14} xs={23} style={{ marginBottom: 25 }}>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}>
              {GlobalLabels.LOCATION}
            </Text>

            <Text style={{ color: " #0b4a72", fontWeight: "600" }}> : </Text>
            <Text>{userLoggedin.location}</Text>
          </Col>
          <Col span={1} />
          <Col md={8} xs={23} style={{ marginBottom: 25 }}>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}>
              {GlobalLabels.CITY}
            </Text>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}> : </Text>
            <Text>
              {userLoggedin.city === null ? "Nil" : userLoggedin.city}
            </Text>
          </Col>
        </Row>

        <Row justify={"center"}>
          <Col span={1} />
          <Col md={14} xs={23} style={{ marginBottom: 25 }}>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}>
              {GlobalLabels.STATE}
            </Text>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}> : </Text>
            <Text>{userLoggedin.state}</Text>
          </Col>
          <Col span={1} />
          <Col md={8} xs={23} style={{ marginBottom: 25 }}>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}>
              {GlobalLabels.COUNTRY}
            </Text>
            <Text style={{ color: " #0b4a72", fontWeight: "600" }}> : </Text>
            <Text>{userLoggedin.country}</Text>
          </Col>
        </Row>
        <Divider />
      </Drawer>

      <ModalDisplay
        openModal={logoutConfimmodalOpen}
        closeModal={() => setlogoutConfimmodalOpen(!logoutConfimmodalOpen)}
        footerDisplay={false}
        modalContentJSX={logoutConfimmodal}
      />
    </React.Fragment>
  );
};

export default StudentWrapper;
