import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Toast from "../../components/toast";
import { setLoading } from "../slice/constant";

export const allStudentsRequest = createAsyncThunk(
  "student/setStudentDetails",
  async (token) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let users = result.data.data.map((user, index) => {
        return {
          sNo: `${index + 1}.`,
          id: user.id,
          firstName: user.firstname,
          lastName: user.lastname,
          phoneNumber: user.phonenumber,
          email: user.email,
          location: user.location,
          country: user.country,
          state: user.state,
          city: user.city,
          labs: user.labs,
          approvalStatus: user.approvalstatus,
        };
      });
      return { users, data: result.data };
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch students.");
    }
  }
);

export const createStudentRequest = createAsyncThunk(
  "student/createStudent",
  async ({ token, payload }, thunk) => {
    try {
      let result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/new`,
        payload
      );
      if (result.data.success) {
        if (token) thunk.dispatch(allStudentsRequest(token));
      }
      return result.data;
    } catch (error) {
      if (error.response.data.error.message.includes("Error: ER_DUP_ENTRY")) {
        Toast("warning", "Email already registered !");
        thunk.dispatch(setLoading(false));
      } else {
        Toast("error", "Something went wrong, Couldn't create student.");
        thunk.dispatch(setLoading(false));
      }
    }
  }
);

export const getStudentRequest = createAsyncThunk(
  "student/setUniqueStudent",
  async ({ token, studentId }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let users;
      if (result.data.success) {
        users = result.data.data.map((user) => {
          return {
            id: user.id,
            firstName: user.firstname,
            lastName: user.lastname,
            phone: user.phonenumber,
            email: user.email,
            password: user.password,
            confirmPassword: user.password,
            location: user.location,
            country: user.country,
            state: user.state,
            city: user.city,
            labs: user.labs,
          };
        });
      }
      const [user] = users;
      return { user, data: result.data };
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't get student.");
    }
  }
);

export const updateStudentRequest = createAsyncThunk(
  "student/updateStudent",
  async ({ token, studentId, payload }, thunk) => {
    try {
      let result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/${studentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk.dispatch(allStudentsRequest(token));
      }
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update student.");
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "student/delete",
  async ({ token, studentId }, thunk) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk.dispatch(allStudentsRequest(token));
      }
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't delete student.");
    }
  }
);
