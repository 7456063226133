import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import LabSlice from "../slice/LabSlice";
import StaffSlice from "../slice/StaffSlice";
import StudentSlice from "../slice/StudentSlice";
import UserSlice from "../slice/UserSlice";
import constantSlice from "../slice/constant";
import InstanceSlice from "../slice/InstanceSlice";
import { encryptTransform } from "redux-persist-transform-encrypt";

const encryptor = encryptTransform({
  secretKey: "root-key",
  onError: function (error) {
    console.error("Encryption error:", error);
  },
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "instance"],
  transforms: [encryptor],
};

const rootReducer = combineReducers({
  labs: LabSlice,
  staff: StaffSlice,
  student: StudentSlice,
  user: UserSlice,
  constant: constantSlice,
  instance: InstanceSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: thunk,
    }),
});

export const persistor = persistStore(store);

export default store;
