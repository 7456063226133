import { Col, Layout, Row, Spin } from "antd";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import AuthVerify from "../components/auth-verify";
import SideBar from "../containers/admin-panel/side-bar";
import StudentWrapper from "../containers/student/student-wrapper";
import TopNavBar from "../containers/top-nav-bar";
import {
  AdminPrivateRoutes,
  StudentPrivateRoutes,
  publicRoutes,
} from "../utils/constants/app-routes";

const AppRoutes = () => {
  const auth = useSelector((state) => state.user.isAuth);
  // console.log("🚀 ~ AppRoutes ~ auth:", auth)
  const userDetails = useSelector((state) => state.user.credentials);
  // console.log("🚀 ~ AppRoutes ~ userDetails:", userDetails)
  const { role } = userDetails;
  const location = useLocation();
  const { Header, Footer, Content } = Layout;

  return (
    <>
      {auth ? (
        " "
      ) : (
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            minHeight: "75px",
            justifyContent: "center",
            shadowColor: "transparent",
            shadowRadius: 0,
            shadowOffset: {
              height: 0,
            },
          }}
        >
          <TopNavBar isLoggedIn={auth} />
        </Header>
      )}
      <Layout>
        {/* SIDE BAR */}
        {auth &&
          role === "admin" &&
          (!location.pathname.includes("start-lab") ? <SideBar /> : null)}
        {/* PRIVATE & PUBLIC ROUTES */}
        <Layout>
          <Content style={{ backgroundColor: "#f7f7f7", minHeight: "89.5vh" }}>
            <Routes>
              {auth ? (
                <React.Fragment>
                  {role === "admin" ? (
                    <React.Fragment>
                      {AdminPrivateRoutes.map((route, index) => {
                        let Component = route.component;
                        return (
                          <Route
                            key={`route-${index}`}
                            path={route.path}
                            element={
                              <Suspense
                                fallback={
                                  <Row
                                    justify="center"
                                    style={{ lineHeight: "697px" }}
                                  >
                                    <Col>
                                      <Spin size="large" />
                                    </Col>
                                  </Row>
                                }
                              >
                                <Component />
                              </Suspense>
                            }
                          />
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {StudentPrivateRoutes.map((route, index) => {
                        let Component = route.component;
                        return (
                          <Route
                            key={`route-${index}`}
                            path={route.path}
                            element={
                              <Suspense
                                fallback={
                                  <Row
                                    justify="center"
                                    style={{ lineHeight: "697px" }}
                                  >
                                    <Col>
                                      <Spin size="large" />
                                    </Col>
                                  </Row>
                                }
                              >
                                <StudentWrapper>
                                  <Component />
                                </StudentWrapper>
                              </Suspense>
                            }
                          />
                        );
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {publicRoutes.map((route, index) => {
                    let Component = route.component;
                    return (
                      <Route
                        key={`route-${index}`}
                        path={route.path}
                        element={
                          <Suspense
                            fallback={
                              <Row
                                justify="center"
                                style={{ lineHeight: "697px" }}
                              >
                                <Col>
                                  <Spin size="large" />
                                </Col>
                              </Row>
                            }
                          >
                            <Component />
                          </Suspense>
                        }
                      />
                    );
                  })}
                </React.Fragment>
              )}
            </Routes>
          </Content>
          {/* {!location.pathname.includes("start-lab") ? ( */}
            <Footer style={{ textAlign: "center", backgroundColor: "#001529", color: '#fff', padding: '10px' }}>
              All Rights Reserved by SkillsDA® 2023
            </Footer>
          {/* ) : null} */}
        </Layout>
      </Layout>
      <AuthVerify />
    </>
  );
};

export default AppRoutes;
