import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Toast from "../../components/toast";
import { setLoading } from "../slice/constant";

export const allStaffsRequest = createAsyncThunk(
  "staff/setStaffDetails",
  async (token) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/staffs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let users = result.data.data.map((user, index) => {
        return {
          sNo: `${index + 1}.`,
          id: user.id,
          firstName: user.firstname,
          lastName: user.lastname,
          phoneNumber: user.phonenumber,
          email: user.email,
          location: user.location,
          country: user.country,
          state: user.state,
          city: user.city,
        };
      });
      return { users, data: result.data };
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch staffs.");
    }
  }
);

export const createStaffRequest = createAsyncThunk(
  "staff/createStaff",
  async ({ token, payload }, thunk) => {
    try {
      let result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/staffs/new`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk.dispatch(allStaffsRequest(token));
      }
      return result.data;
    } catch (error) {
      if (error.response.data.error.message.includes("Error: ER_DUP_ENTRY")) {
        Toast("warning", "Email already registered !");
        thunk.dispatch(setLoading(false));
      } else {
        Toast("error", "Something went wrong, Couldn't create staff.");
        thunk.dispatch(setLoading(false));
      }
    }
  }
);

export const getStaffRequest = createAsyncThunk(
  "staff/setUniqueStaff",
  async ({ token, staffId }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/staffs/${staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let users;
      if (result.data.success) {
        users = result.data.data.map((user) => {
          return {
            id: user.id,
            firstName: user.firstname,
            lastName: user.lastname,
            phone: user.phonenumber,
            email: user.email,
            password: user.password,
            confirmPassword: user.password,
            location: user.location,
            country: user.country,
            state: user.state,
            city: user.city,
          };
        });
      }
      const [user] = users;
      return { user, data: result.data };
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch staff.");
    }
  }
);

export const updateStaffRequest = createAsyncThunk(
  "staff/updateStaff",
  async ({ token, staffId, payload }, thunk) => {
    try {
      let result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/staffs/${staffId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk.dispatch(allStaffsRequest(token));
      }
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update staff.");
    }
  }
);

export const deleteStaff = createAsyncThunk(
  "staff/deleteStaff",
  async ({ token, staffId }, thunk) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/staffs/${staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk.dispatch(allStaffsRequest(token));
      }
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't delete staff.");
    }
  }
);
