import { message } from "antd";

const Toast = (toastType, toastContent, toastDuration = 3) => {
  message.open({
    type: toastType,
    content: toastContent,
    duration: toastDuration,
  });
};

export default Toast;
