import Cloud from "../../assets/img/labrepoimages/Cloud.jpg";
import Devops from "../../assets/img/labrepoimages/Devops.jpg";
import Foren from "../../assets/img/labrepoimages/Foren.jpg";
import Malware from "../../assets/img/labrepoimages/Malware.jpg";
import Mobile from "../../assets/img/labrepoimages/Mobile.jpg";
import Network from "../../assets/img/labrepoimages/Network.jpg";
import Notfound from "../../assets/img/labrepoimages/Notfound.jpg";
import ReverseEng from "../../assets/img/labrepoimages/ReverseEng.jpg";
import Web from "../../assets/img/labrepoimages/Web.jpg";
import info from "../../assets/img/labrepoimages/info.jpg";

export const GlobalLabels = {
  //MCQ
  MCQ_QUESTION_TYPE: "Question Type",
  MCQ_QUESTION: "Question",
  MCQ_ANSWER: "Answer",
  MCQ_STATUS: "Status",
  ACTIONS_EDIT: "Edit",
  ACTIONS_DELETE: "Delete",
  MCQ_OPTIONS: "Options",
  // Public Route Names
  HOME: "Home",
  ABOUT_US: "About us",
  LAB_REPO: "Lab Repository",
  LIST_OF_LABS: "List of labs",
  ATTACKER: "Attacker",
  Victim: "Victim",
  HELP_DESK: "Help desk",
  USER_DATA: "User Data",
  LOGIN: "Login",
  REGISTRATION: "Registration",
  REMEMBER: "Remember",
  ME: "Me",
  FORGOT_PASSWORD: "Forgot Password",
  RESET_PASSWORD: "Reset Password",
  WITH: "With",
  GOOGLE: "Google",
  APPLE: "Apple",
  // Admin Panel Names
  ADMIN_LOGIN: "Admin Login",
  USERNAME: "User Name",
  DASHBOARD: "Dashboard",
  USER: "User",
  REPORTS: "Reports",
  REPORTS_USER_ACTIVITY_OVERVIEW: "User Activity Overview",
  REPORTS_USER_SESSION_DETAILS: "User Session Details",
  REPORTS_FEEDBACK_SUMMARY: "Feedback Summary",
  REPORTS_USER_PROCESS_TRACKING: "User Process tracking",
  REPORTS_RESOURCE_UTILIZATION: "User Resource Utilization",
  STAFF: "Staff",
  STUDENT: "Student",
  DETAILS: "Details",
  INFORMATION: "Information",
  LABS: "Labs",
  ADD_LAB: "Add Lab",
  ALL_LABS: "All Labs",
  LAB_CONFIG: "Lab Configuration",
  LAB_PERFORMANCE: "Performance",
  ADD_REPOSITORY: "Add Repository",
  ADD_STAFF: "Add Staff",
  ADD_STUDENT: "Add Student",
  SUPPORT: "Support",
  // Table Col Names
  NAME: "Name",
  EMAIL: "Email",
  PHONE: "Phone",
  ADDRESS: "Address",
  TITLE: "Title",
  ISSUE: "Issue",
  CATEGORY: "Category",
  ROLE: "Role",
  // Lab Form Labels
  LAB: "Lab",
  CREATED_BY: "Created By",
  LAB_LEVEL: "Lab Level",
  LAB_TITLE: "Lab Title",
  LAB_OWNER: "Lab Owner",
  THUMBNAIL: "Thumbnail",
  KEY_FILE: "Key File",
  AMI_ID: "Ami-ID",
  SECURITY_GROUPS_NAME: "Security Groups Name",
  SECURITY_GROUPS_ID: "Security Groups ID",
  SUBNET_ID: "Subnet ID",
  VPC_ID: "Vpc ID",
  KEY_PAIRS: "Key Pairs",
  CLUSTER_NAME: "Cluster Name",
  CLUSTER_TASK_NAME: "Task Name",
  CLUSTER_TASK_IMAGE: "Task Image URI",
  CLUSTER_SERVICE: "Service Name",
  MACHINE_INSTANCE: "Machine Instance Type",
  TOPOLOGY: "Topology",
  UPLOAD: "Upload",
  NO_OF_MACH: "No of Machines / Instances",
  LAB_TIME: "Lab Time",
  INST_TYPE: "Instance type",
  HOSTING_DETAILS: "HOSTING DETAILS",
  LAB_INSTRUCTIONS: "LAB INSTRUCTIONS",
  LAB_DESCPRITIONS: "LAB DESCPRITIONS",
  LAB_DETAILS: "LAB DETAILS",
  // Reg form Labels
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  LOCATION: "Location",
  COUNTRY: "Country",
  STATE: "State",
  CITY: "City",
  OTP: "Otp",
  RESET: "Reset",
  SUBMIT: "Submit",
  LOGOUT: "Logout",
  // Common Labels
  STUDENT_REGISTRATION_APPROVALS: "Student Registration Approvals",
  UPLOAD_IMAGE_HERE: "Upload image here",
  CONTACT: "Contact",
  DELETE: "Delete",
  // Button Labels
  VIEW_STUDENTS: "View Students",
  APPROVE: "Approve",
  APPROVED: "Approved",
  APPROVAL: "Approval",
  APPROVAL_STATUS: "Approval Status",
  DECLINE: "Decline",
  DECLINED: "Declined",
  CANCEL: "Cancel",
  SAVE: "Save",
};

export const labRepositaries = [
  {
    id: 101,
    name: "networkSecurity",
    path: "network-security",
    label: "Network Security",
    img: Network,
    noOfLabs: 0,
  },
  {
    id: 102,
    name: "webApplicationSecurity",
    path: "web-application-security",
    label: "Web Application Security",
    img: Web,
    noOfLabs: 0,
  },
  {
    id: 103,
    name: "mobileSecurity",
    path: "mobile-security",
    label: "Mobile Security",
    img: Mobile,
    noOfLabs: 0,
  },
  {
    id: 104,
    name: "wirelessSecurity",
    path: "wireless-security",
    label: "Wireless Security",
    img: Notfound,
    noOfLabs: 0,
  },
  {
    id: 105,
    name: "dataSecurity",
    path: "data-security",
    label: " Data Security",
    img: Notfound,
    noOfLabs: 0,
  },
  {
    id: 106,
    name: "informationSecurity",
    path: "information-security",
    label: "Information Security",
    img: info,

    noOfLabs: 0,
  },
  {
    id: 107,
    name: "cloudSecurity",
    path: "cloud-security",
    label: "Cloud Security",
    img: Cloud,
    noOfLabs: 0,
  },
  {
    id: 108,
    name: "DevSecOps",
    path: "DevSecOps",
    label: "Dev Sec Ops",
    img: Devops,
    noOfLabs: 0,
  },
  {
    id: 109,
    name: "malwareAnalysis",
    path: "malware-analysis",
    label: "Malware Analysis",
    img: Malware,
    noOfLabs: 0,
  },
  {
    id: 110,
    name: "reverseEngineering",
    path: "reverse-engineering",
    label: "Reverse Engineering",
    img: ReverseEng,
    noOfLabs: 0,
  },
  {
    id: 111,
    name: "vulnerabilityAssesmentPenetrationTesting",
    path: "vulnerability-assesment-penetration-testing",
    label: "Vulnerability Assesment Penetration Testing",
    img: Notfound,
    noOfLabs: 0,
  },
  {
    id: 112,
    name: "digitalForensics",
    path: "digital-forensics",
    label: "Digital Forensics",
    img: Foren,
    noOfLabs: 0,
  },
  {
    id: 113,
    name: "ioTSecurity",
    path: "ioT-Security",
    label: "IoT Security",
    img: Notfound,
    noOfLabs: 0,
  },
];

// TOP NAV ITEMS
export const TopNavItems = [
  {
    key: "/",
    label: GlobalLabels.HOME,
  },
  {
    key: "/help-desk",
    label: GlobalLabels.HELP_DESK,
  },
  {
    key: "/lab-repository",
    label: GlobalLabels.LAB_REPO,
  },
];

export const LabCheckBoxOptions = ["Docker", "VM Ware", "Pnet"];

export const hostingTypes = ["AWS", "Server Space"];

export const ssImageIdWindows = [
  "Windows-Server 2022 Std-X64",
  "Windows-Server 2019 Std-X64",
];
export const ssImageIdLinux = [
  "Oracle-9.1-X64",
  "Oracle-8.6-X64",
  "CentOS-8.3-X64",
  "CentOS-7.9-X64",
  "Debian-12-X64",
  "Debian-11.6-X64",
  "Ubuntu-22.04-X64",
  "Ubuntu-20.04-X64",
  "Ubuntu-18.04-X64",
];

export const ssCpu = [
  { label: "1 GB", value: 1 },
  { label: "2 GB", value: 2 },
  { label: "3 GB", value: 3 },
  { label: "4 GB", value: 4 },
];

export const ssRam = [
  { label: "1 GB", value: 1024 },
  { label: "2 GB", value: 2048 },
  { label: "3 GB", value: 3072 },
  { label: "4 GB", value: 4096 },
  { label: "8 GB", value: 8192 },
];
export const ssDiskVolumes = [
  { label: "25 GB", value: 25600 },
  { label: "30 GB", value: 30720 },
  { label: "40 GB", value: 40960 },
  { label: "50 GB", value: 51200 },
  { label: "60 GB", value: 61440 },
  { label: "70 GB", value: 71680 },
  { label: "80 GB", value: 81920 },
  { label: "90 GB", value: 92160 },
  { label: "100 GB", value: 102400 },
];

/*
 * Quill editor modules
 */
export const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    // parchment: Quill.import('parchment'),
    modules: ["Resize", "DisplaySize"],
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const editorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const Capitalaize = (data) => {
  if (data) {
    const words = data.split(/\s+/);
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }
};

export const descriptionTemplate =
  "<h5>Objective:</h5> <br> <h5>Synopsis:</h5> <br> <h5>Prerequisite:</h5> <br> <h5>Requirements:</h5> <br> <h5>Conclusion:</h5>";

export const status = [
  {
    id: 1,
    label: "All",
    options: "All",
  },
  {
    id: 2,
    label: "In Progress",
    options: "In Progress",
  },
  {
    id: 3,
    label: "Completed",
    options: "Completed",
  },
];

export const generateRandomKey = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const authData = {
  username: process.env.REACT_APP_AUTHDATA_USERNAME,
  password: process.env.REACT_APP_AUTHDATA_PASSWORD,
};

export const connectionForGuacamoleWindows = {
  parentIdentifier: "ROOT",
  name: "",
  protocol: "rdp",
  parameters: {
    port: "3389",
    "read-only": "",
    "swap-red-blue": "",
    cursor: "",
    "color-depth": "Low color (16bit)",
    "clipboard-encoding": "",
    "disable-copy": "",
    "disable-paste": "",
    "dest-port": "",
    "recording-exclude-output": "",
    "recording-exclude-mouse": "",
    "recording-include-keys": "",
    "create-recording-path": "",
    "enable-sftp": "",
    "sftp-port": "",
    "sftp-server-alive-interval": "",
    "enable-audio": "",
    security: "",
    "disable-auth": "",
    "ignore-cert": "true",
    "gateway-port": "",
    "server-layout": "",
    timezone: "",
    console: "",
    width: "",
    height: "",
    dpi: "",
    "resize-method": "",
    "console-audio": "",
    "disable-audio": "",
    "enable-audio-input": "",
    "enable-printing": "",
    "enable-drive": "",
    "create-drive-path": "",
    "enable-wallpaper": "",
    "enable-theming": "",
    "enable-font-smoothing": "",
    "enable-full-window-drag": "",
    "enable-desktop-composition": "",
    "enable-menu-animations": "",
    "disable-bitmap-caching": "",
    "disable-offscreen-caching": "",
    "disable-glyph-caching": "",
    "preconnection-id": "",
    hostname: "",
    username: process.env.REACT_APP_LAB_USERNAME,
    password: process.env.REACT_APP_LAB_PASSWORD,
    domain: "",
    "gateway-hostname": "",
    "gateway-username": "",
    "gateway-password": "",
    "gateway-domain": "",
    "initial-program": "",
    "client-name": "",
    "printer-name": "",
    "drive-name": "",
    "drive-path": "",
    "static-channels": "",
    "remote-app": "",
    "remote-app-dir": "",
    "remote-app-args": "",
    "preconnection-blob": "",
    "load-balance-info": "",
    "recording-path": "",
    "recording-name": "",
    "sftp-hostname": "",
    "sftp-host-key": "",
    "sftp-username": "",
    "sftp-password": "",
    "sftp-private-key": "",
    "sftp-passphrase": "",
    "sftp-root-directory": "",
    "sftp-directory": "",
  },
  attributes: {
    "max-connections": "2",
    "max-connections-per-user": "",
    weight: "",
    "failover-only": "",
    "guacd-port": "",
    "guacd-encryption": "",
    "guacd-hostname": "",
  },
};

export const connectionForGuacamoleLinux = {
  parentIdentifier: "ROOT",
  name: "",
  protocol: "ssh",
  parameters: {
    port: "22",
    "read-only": "",
    "swap-red-blue": "",
    cursor: "",
    "color-depth": "Low color (16bit)",
    "clipboard-encoding": "",
    "disable-copy": "",
    "disable-paste": "",
    "dest-port": "",
    "recording-exclude-output": "",
    "recording-exclude-mouse": "",
    "recording-include-keys": "",
    "create-recording-path": "",
    "enable-sftp": "",
    "sftp-port": "",
    "sftp-server-alive-interval": "",
    "enable-audio": "",
    "color-scheme": "",
    "font-size": "",
    scrollback: "",
    timezone: null,
    "server-alive-interval": "",
    backspace: "",
    "terminal-type": "",
    "create-typescript-path": "",
    hostname: "",
    "host-key": "",
    "private-key": "",
    username: process.env.REACT_APP_LAB_USERNAME,
    password: process.env.REACT_APP_LAB_PASSWORD,
    passphrase: "",
    "font-name": "",
    command: "",
    locale: "",
    "typescript-path": "",
    "typescript-name": "",
    "recording-path": "",
    "recording-name": "",
    "sftp-root-directory": "",
  },
  attributes: {
    "max-connections": "",
    "max-connections-per-user": "",
    weight: "",
    "failover-only": "",
    "guacd-port": "",
    "guacd-encryption": "",
    "guacd-hostname": "",
  },
};

export const newUserGuacamole = {
  username: "",
  password: "",
  attributes: {
    disabled: "",
    expired: "",
    "access-window-start": "",
    "access-window-end": "",
    "valid-from": "",
    "valid-until": "",
    timezone: null,
    "guac-full-name": "",
    "guac-organization": "",
    "guac-organizational-role": "",
  },
};

export const assignConnection = [
  {
    op: "add",
    path: "",
    value: "READ",
  },
];
