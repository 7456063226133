import { useEffect, useState, React } from "react";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Row,
} from "antd";
import { useNavigate } from "react-router";
import LightthemeLOGO from "../../assets/img/logo/SkillsDA Logo with Text - Blue Colour.png";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { RiSendPlane2Line } from "react-icons/ri";
import { SiApple } from "react-icons/si";
import { useDispatch } from "react-redux";
import Toast from "../../components/toast";
import { userLoginRequest } from "../../redux/service/UserService";
import { GlobalLabels } from "../../utils/constants/common";
import Cookies from "js-cookie";

const Login = () => {
  const [rememberMe, setRememberMe] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    if (Object.values(values).length !== 0) {
      dispatch(userLoginRequest(values))
        .unwrap()
        .then((res) => {
          if (res.success) {
            // localStorage.setItem("auth", true);
            // localStorage.setItem("user", JSON.stringify(res.data));
            debugger;
            Cookies.set("token", res.data.token, {
              expires: 1, // 7 days expiration
              secure: true, // Ensure the cookie is sent only over HTTPS
              // httpOnly: true, // Ensure the cookie is not accessible via JavaScript (Note: `httpOnly` is not directly supported by `js-cookie`, it needs to be set on the server-side)
              sameSite: "Strict", // Helps mitigate CSRF attacks
            });

            Cookies.set("user", JSON.stringify(res.data), {
              expires: 1, // 7 days expiration
              secure: true, // Ensure the cookie is sent only over HTTPS
              // httpOnly: true, // Ensure the cookie is not accessible via JavaScript (Note: `httpOnly` is not directly supported by `js-cookie`, it needs to be set on the server-side)
              sameSite: "Strict", // Helps mitigate CSRF attacks
            });

            Cookies.set("email", JSON.stringify(res.data.email), {
              expires: 1, // 7 days expiration
              secure: true, // Ensure the cookie is sent only over HTTPS
              // httpOnly: true, // Ensure the cookie is not accessible via JavaScript (Note: `httpOnly` is not directly supported by `js-cookie`, it needs to be set on the server-side)
              sameSite: "Strict", // Helps mitigate CSRF attacks
            });

            navigate("/user");
            Toast("success", "Login success");
          } else {
            Toast("warning", res.message);
          }
        })
        .catch((error) => {
          Toast("error", "Invalid credentials");
        });
    }
    if (rememberMe) {
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.removeItem("email");
    }
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe");

    if (storedRememberMe === "true") {
      setRememberMe(true);
    }
  }, []);

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="studentlogin-formContainer"
    >
      <Row justify={"center"}>
        <img
          src={LightthemeLOGO}
          className="logo-container"
          alt="logo"
          width={200}
          height={55}
        />
      </Row>

      <Form.Item
        label={<label className="labelHeadings">{GlobalLabels.EMAIL}</label>}
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter your email!",
          },
          {
            type: "email",
            message: "Please enter a valid email!",
          },
        ]}
      >
        <Input
          size="large"
          prefix={<AiOutlineMail className="color-skills-da" />}
          placeholder="Enter Email Address"
        />
      </Form.Item>

      <Form.Item
        label={<label className="labelHeadings">{GlobalLabels.PASSWORD}</label>}
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
        ]}
      >
        <Input.Password
          size="large"
          prefix={<AiOutlineLock className="color-skills-da" />}
          placeholder="Enter Password"
        />
      </Form.Item>

      <Form.Item>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                Remember me!
              </Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <a href="/forgot-password">Forgot password?</a>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        <ConfigProvider theme={{ token: { colorPrimary: "#0b4a72" } }}>
          <Button block size="large" type="primary" htmlType="submit">
            <div className="modelContents">
              <span>Sign In</span>
              <RiSendPlane2Line />
            </div>
          </Button>
        </ConfigProvider>
      </Form.Item>

      <Divider orientation="center" plain>
        OR
      </Divider>

      <ConfigProvider theme={{ token: { colorPrimary: "#0b4a72" } }}>
        <Button block size="large" type="default" style={{ margin: " 10px 0" }}>
          <div className="modelContents">
            <FcGoogle />
            <span>Continue with Google</span>
          </div>
        </Button>
      </ConfigProvider>

      <ConfigProvider theme={{ token: { colorPrimary: "black" } }}>
        <Button block size="large" type="primary" style={{ margin: " 10px 0" }}>
          <div className="modelContents">
            <SiApple />
            <span>Continue with Apple</span>
          </div>
        </Button>
      </ConfigProvider>
    </Form>
  );
};

export default Login;
