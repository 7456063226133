import { createSlice } from "@reduxjs/toolkit";
import {
    allStudentsRequest,
    createStudentRequest,
    deleteStudent,
    getStudentRequest,
    updateStudentRequest,
} from "../service/StudentService";

const state = {
  isFetching: false,
  error: "",
  studentDetails: [],
  uniqueStudent: {},
};

const StudentRegistSlice = createSlice({
  name: "student",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    // get students
    builder.addCase(allStudentsRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(allStudentsRequest.fulfilled, (state, action) => {
      const students = action.payload?.users;
      state.isFetching = false;
      state.studentDetails = students ? students : [];
    });
    builder.addCase(allStudentsRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // Create student
    builder.addCase(createStudentRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(createStudentRequest.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(createStudentRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // get unique student
    builder.addCase(getStudentRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getStudentRequest.fulfilled, (state, action) => {
      state.isFetching = false;
      state.uniqueStudent = action.payload.user;
    });
    builder.addCase(getStudentRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // update student
    builder.addCase(updateStudentRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(updateStudentRequest.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(updateStudentRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });

    // delete student
    builder.addCase(deleteStudent.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(deleteStudent.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(deleteStudent.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.message;
    });
  },
});

export const { setStudentDetails, setUniqueStudent } =
  StudentRegistSlice.actions;
export default StudentRegistSlice.reducer;
