import { Modal } from "antd";

const ModalDisplay = ({
  title,
  openModal,
  footerDisplay,
  className,
  closeModal,
  width,
  zIndex,
  modalContentJSX,
}) => {
  return (
    <Modal
      mask={true}
      title={title}
      open={openModal}
      closable={true}
      onCancel={closeModal}
      className={className}
      footer={footerDisplay}
      width={width}
      zIndex={zIndex}
      style={{ background: "white", borderRadius: "15px" }}
    >
      {modalContentJSX()}{" "}
    </Modal>
  );
};

export default ModalDisplay;
