import { Spin } from "antd";
import { useSelector } from "react-redux";

const LoadingWrapper = ({ children }) => {
  const loading = useSelector((state) => state.constant.loading);

  return (
    <Spin style={{ margin: "190px 0px" }} spinning={loading} size="large">
      {children}
    </Spin>
  );
};

export default LoadingWrapper;
