import React from "react";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import Buttons from "../Button";

const ModalContent = (
  alert = "Set Alert Message Here",
  onNo = () => {},
  onYes = () => {},
  noButton = true,
  yesButton = true,
  noButtonName = "No",
  yesbuttonName = "Yes"
) => {
  // noButton = true,
  // yesButton = true,
  // noButtonName = "No",
  // yesbuttonName = "Yes"
  // These four are used only for auth-verify modal.
  return (
    <React.Fragment>
      <div className="font-size-large">{alert}</div>
      <div className="menubarRightButtons deletemodelFooter">
        {noButton ? (
          <Buttons
            title={
              <div className="modelContents">
                <RxCrossCircled size={18} />
                <span>{noButtonName}</span>
              </div>
            }
            onClick={onNo}
            colorPrimaryHover="red"
            type="default"
          />
        ) : null}

        {yesButton ? (
          <Buttons
            title={
              <div className="modelContents">
                <span>{yesbuttonName}</span>
                <RxCheckCircled size={18} />
              </div>
            }
            onClick={onYes}
            colorPrimary="red"
            type="primary"
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default ModalContent;
