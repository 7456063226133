import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Toast from "../../components/toast";
import { allStudentsRequest } from "./StudentService";

export const adminloginRequest = createAsyncThunk(
  "user/setAdminDetails",
  async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/login`,
        data
      );
      return result.data;
    } catch (error) {}
  }
);

export const userLoginRequest = createAsyncThunk(
  "user/setUserDetails",
  async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/login`,
        data
      );
      return result.data;
    } catch (error) {}
  }
);

export const userLMSLoginRequest = createAsyncThunk(
  "user/setUserDetails1",
  async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/lms-login`,
        data
      );
      return result.data;
    } catch (error) {}
  }
);

export const redirectRequest = createAsyncThunk(
  "user/redirectRequest",
  async ({ email }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/students/student/${email}`
      );
      return result.data;
    } catch (error) {}
  }
);

export const forgotPasswordRequest = createAsyncThunk(
  "user/forgotPasswordRequest",
  async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/forgot-password`,
        data
      );
      return result.data;
    } catch (error) {}
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "user/resetPasswordRequest",
  async ({ payload, token, id }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reset-password/${id}/${token}`,
        { password: payload }
      );
      return result.data;
    } catch (error) {}
  }
);

export const labAssignRequest = createAsyncThunk(
  "user/labAssign",
  async ({ studentId, payload }) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/student/labs/assign/${studentId}`,
        { labs: payload }
      );
      if (result.data.success) return result.data;
    } catch (error) {}
  }
);

export const approvalAssignRequest = createAsyncThunk(
  "user/approve",
  async ({ studentId, payload, token }, thunk) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/student/status/${studentId}`,
        { approvalstatus: payload }
      );
      if (result.data.success) thunk.dispatch(allStudentsRequest(token));
      return result.data;
    } catch (error) {}
  }
);

export const getCountriesRequest = createAsyncThunk(
  "register/countries",
  async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/countries`
      );
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch countries", 3);
    }
  }
);

export const getStatesRequest = createAsyncThunk(
  "register/states",
  async (id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/states/${id}`
      );
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch states", 3);
    }
  }
);

export const getCityRequest = createAsyncThunk("register/city", async (id) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/cities/${id}`
    );
    return result.data;
  } catch (error) {
    Toast("error", "Something went wrong, Couldn't fetch cities", 3);
  }
});
