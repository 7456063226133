import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Toast from "../../components/toast";

export const getAllRepoRequest = createAsyncThunk(
  "labs/getAllRepo",
  async (data) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/labs/repository`
      );
      if (result.data.success) {
        const labCategory = result.data.data.repository.map((lab, index) => {
          let totallabs = 0;

          result.data.data.labsCountByRepository.forEach((element) => {
            if (element.categoryname === lab.categoryname) {
              totallabs = element.total_labs;
            }
          });
          let routePath = "";
          lab.categoryname.split(" ").forEach((ele) => {
            routePath = !routePath
              ? ele.toLocaleLowerCase()
              : `${routePath}-${ele.toLocaleLowerCase()}`;
          });
          return {
            routePath,
            totallabs,
            siderPathKey: `admin/lab-repository/${routePath}/${lab.id}`,
            publicPathKey: `/lab-repository/${routePath}/${lab.id}`,
            ...lab,
          };
        });
        return { labCategory, data: result.data };
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch repositories.", 3);
    }
  }
);

export const createLabRepoRequest = createAsyncThunk(
  "labs/createLabCategory",
  async ({ token, categoryname, updated_by, thumbnailurl }, thunk) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/labs/repository/new`,
        {
          categoryname: categoryname,
          updated_by: updated_by,
          created_by: updated_by,
          repoimageurl: thumbnailurl,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      if (result.data.success) {
        thunk
          .dispatch(getAllRepoRequest({ token: token }))
          .unwrap()
          .then((res) => {
            if (res.data.success) {
              return { labCategory: res.labCategory, data: result.data };
            }
          })
          .catch((error) => {});
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't create repository.", 3);
    }
  }
);

export const updateLabRepoRequest = createAsyncThunk(
  "labs/updateLabCategory",
  async ({ token, repoId, categoryname, updated_by, thumbnailurl }, thunk) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/labs/repository/${repoId}`,
        {
          categoryname: categoryname,
          updated_by: updated_by,
          repoimageurl: thumbnailurl,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk
          .dispatch(getAllRepoRequest({ token: token }))
          .unwrap()
          .then((res) => {
            if (res.data.success) {
              return { labCategory: res.labCategory, data: result.data };
            }
          })
          .catch((error) => {});
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update repository.", 3);
    }
  }
);

export const deleteLabRepoRequest = createAsyncThunk(
  "labs/deleteLabCategory",
  async ({ token, repoId }, thunk) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/labs/repository/${repoId}`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        thunk
          .dispatch(getAllRepoRequest({ token: token }))
          .unwrap()
          .then((res) => {
            if (res.data.success) {
              return { labCategory: res.labCategory, data: result.data };
            }
          })
          .catch((error) => {});
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't delete repository.", 3);
    }
  }
);

export const getLabsByCategoryIdRequest = createAsyncThunk(
  "labs/getLabsByLabId",
  async ({ repoId }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/category/${repoId}`
      );
      if (result.data.success) {
        return { data: result.data, repoId };
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch labs.", 3);
    }
  }
);

export const getLabByIdRequest = createAsyncThunk(
  "labs/getLabsByRepoId",
  async ({ labId, token, isVictim }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/${labId}`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      const [lab] = result.data.data;
      const tempData = result.data.data;
      for (let index = 0; index < tempData.length; index++) {
        if (tempData[index].h_type === 1 || tempData[index].h_type === "1") {
          lab.attacker = {
            amiid: tempData[index].s_amiid,
            hostingplatform: tempData[index].s_hosting_platform,
            machineinstancetype: tempData[index].s_machine_type,
            securitygroupsname: tempData[index].s_security_group,
            securitygroupsid: tempData[index].s_security_group_id,
            keyPairs: tempData[index].s_key_pairs,
            subnetId: tempData[index].s_subnet_id,
            vpcId: tempData[index].s_vpc_id,
            keyFilePath: tempData[index].s_key_file_path,
          };
        } else {
          lab.receiver = {
            amiid: tempData[index].s_amiid,
            hostingplatform: tempData[index].s_hosting_platform,
            machineinstancetype: tempData[index].s_machine_type,
            securitygroupsname: tempData[index].s_security_group,
            securitygroupsid: tempData[index].s_security_group_id,
            keyPairs: tempData[index].s_key_pairs,
            subnetId: tempData[index].s_subnet_id,
            vpcId: tempData[index].s_vpc_id,
            keyFilePath: tempData[index].s_key_file_path,
          };
        }
      }
      // console.log("🚀 ~ result.data.data:", result.data.data);
      lab.instancetype = JSON.parse(lab.instancetype);
      if (result.data.success) {
        return { lab: lab, data: result.data, isVictim };
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch lab.", 3);
    }
  }
);

export const getLabTimerByLabIdRequest = createAsyncThunk(
  "labs/getLabTimerByLabIdRequest",
  async ({ labId, token, payload }) => {
    try {
      // debugger
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/get-timer/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      // const [lab] = result.data.data;
      // lab.instancetype = JSON.parse(lab.instancetype);
      // if (result.data.success) {
      return { data: result.data };
      // }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch lab.", 3);
    }
  }
);

export const getLabSummaryStatus = createAsyncThunk(
  "labs/getLabSummaryStatus",
  async ({ labId, token, student_id }) => {
    try {
      // debugger
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/summary/${labId}/${student_id}`,
        [],
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      // const [lab] = result.data.data;
      // lab.instancetype = JSON.parse(lab.instancetype);
      // if (result.data.success) {
      return { data: result.data };
      // }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't fetch lab.", 3);
    }
  }
);

export const addLabRequest = createAsyncThunk(
  "labs/addLab",
  async ({ payload, token }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/add-lab`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't add lab.", 3);
    }
  }
);

export const updateLabRequest = createAsyncThunk(
  "labs/updateLab",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const deleteLabRequest = createAsyncThunk(
  "labs/deleteLab",
  async ({ labId, token }) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/${labId}`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't delete lab.", 3);
    }
  }
);

export const getAllLabs = createAsyncThunk("labs/getAllLabs", async () => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/labs`
    );

    if (result.data.success) {
      const labCategory = result.data.data.map((lab, index) => {
        let routePath = "";
        lab.categoryname.split(" ").forEach((ele) => {
          routePath = !routePath
            ? ele.toLocaleLowerCase()
            : `${routePath}-${ele.toLocaleLowerCase()}`;
        });
        return {
          routePath,
          siderPathKey: `admin/lab-repository/${routePath}/${lab.categoryid}`,
          publicPathKey: `/lab-repository/${routePath}/${lab.categoryid}`,
          ...lab,
        };
      });
      return { labCategory, data: result.data.data };
    }
  } catch (error) {
    Toast("error", "Something went wrong, Couldn't fetch repositories.", 3);
  }
});

export const updateLabTimer = createAsyncThunk(
  "labs/updateLabTimer",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/timer/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const updateStopMachine = createAsyncThunk(
  "labs/updateStopMachine",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/stop-machine/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const insertOrUpdateMCQ = createAsyncThunk(
  "labs/insertOrUpdateMCQ",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/mcq/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const checkAnswer = createAsyncThunk(
  "labs/checkAnswer",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/mcq/check-answer/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const submitSummary = createAsyncThunk(
  "labs/summary",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/summary/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const getMCQList = createAsyncThunk(
  "labs/getMCQList",
  async ({ token, labId }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/mcq/${labId}`,

        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

export const getMCQListStudent = createAsyncThunk(
  "labs/getMCQListStudent",
  async ({ token, labId }) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/mcq/student/${labId}`,

        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        return result.data;
      }
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't update lab.", 3);
    }
  }
);

// machine start and stop log
export const machineStartStopLog = createAsyncThunk(
  "machineStartStopLog",
  async ({ payload, token, labId }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/log/${labId}`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

// REPORT - STUDENT PERFORMANCE
export const getStudentPerformanceReport = createAsyncThunk(
  "getStudentPerformanceReport",
  async ({ token }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/student-performance`,
        [],
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

// REPORT - USER ACTIVITY OVERVIEW
export const getReportUserActivityOverview = createAsyncThunk(
  "getStudentPerformanceReport",
  async ({ token, payload }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/report/user-activity-overview`,
        payload,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      Toast("error", "Something went wrong, while fetching the data.");
    }
  }
);

// REPORT - USER SESSION DETAILS
export const getReportUserSessionDetails = createAsyncThunk(
  "getStudentPerformanceReport",
  async ({ token }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/student-performance`,
        [],
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      Toast("error", "Something went wrong, while fetching the data.");
    }
  }
);

// REPORT - FEEDBACK SUMMARY
export const getReportFeedbackReport = createAsyncThunk(
  "getStudentPerformanceReport",
  async ({ token }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/summary`,
        [],
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      Toast("error", "Something went wrong, while fetching the data.");
    }
  }
);



//REPORT - USER PROCESS TRACKING
export const getLabMachineLogReport = createAsyncThunk(
  "getStudentPerformanceReport",
  async ({ token }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/report/user-progress-tracking`,
        [],
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      Toast("error", "Something went wrong, while fetching the data.");
    }
  }
);



// REPORT - USER RESOURCE UTILIZATION
export const getReportUserResourceUtilization = createAsyncThunk(
  "getUserResourceUtilizationReport",
  async ({ token }) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/student-resource-utilization`,
        [],
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      Toast("error", "Something went wrong, while fetching the data.");
    }
  }
);


export const updateEndTimeForInstance = createAsyncThunk(
  "getUserResourceUtilizationReport",
  async ({ token, instanceId }) => {
    // Receive instanceId as a parameter
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/admin/lab/student-resource-utilization-update-end-time`,
        { instanceId }, // Pass instanceId in the request body
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      Toast("error", "Something went wrong, while fetching the data.");
    }
  }
);