import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Toast from "../../components/toast";
import { authData } from "../../utils/constants/common";
import { setDefaultInstance, setDefaultVictim } from "../slice/InstanceSlice";

export const linuxStartInstanceRequest = createAsyncThunk(
  "AWSLinuxInstance/start",
  async (payload) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/AWS/EC2Instance/linux/start-lab`,
        payload,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );

      return {
        data: result.data,
        isStartVictim: payload.isStartVictim,
      };
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

export const WindowsStartInstanceRequest = createAsyncThunk(
  "AWSWindowsInstance/start",
  async (payload, thunk, token) => {
    try {
      debugger;
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/AWS/EC2Instance/windows/start-lab`,
        payload,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );
      return { data: result.data, isStartVictim: payload.isStartVictim };
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

export const getTokenRequestInGuacamole = createAsyncThunk(
  "getToken/guacamole",
  async (data, thunk) => {
    try {
      console.log("getTokenRequestInGuacamole");
      console.log(`${process.env.REACT_APP_API_BASE_URL}/guacamole`);
      console.log(`${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/tokens`);
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/guacamole`,
        {
          URL: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/tokens`,
          payload: authData,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          method: "post",
        }
      );

      return result;
    } catch (error) {
      Toast("error", error.response.data.message);
    }
  }
);

export const createConnectionRequestInGuacamole = createAsyncThunk(
  "createConnection/guacamole",
  async (data, thunk) => {
    try {
      //   const result = await axios.post(
      //     `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/connections?token=${data.token}`,
      //     data.payload
      //   );
      console.log("createConnectionRequestInGuacamole");
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/guacamole`,
        {
          URL: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/connections?token=${data.token}`,
          payload: data.payload,
          method: "post",
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
      return error;
    }
  }
);

export const createUserRequestInGuacamole = createAsyncThunk(
  "createUser/guacamole",
  async (data, thunk) => {
    try {
      //   const result = await axios.post(
      //     `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/?token=${data.token}`,
      //     data.payload
      //   );
      console.log("createUserRequestInGuacamole");
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/guacamole`,
        {
          URL: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/${data.payload.username}?token=${data.token}`,
          URL1: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/?token=${data.token}`,
          payload: data.payload,
          method: "put",
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

export const assignConnectionInGuacamole = createAsyncThunk(
  "assignConnection/guacamole",
  async (data) => {
    try {
      //   const result = await axios.patch(
      //     `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/${data.guacUsername}/permissions?token=${data.token}`,
      //     data.payload
      //   );

      console.log("assignConnectionInGuacamole :");
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/guacamole`,
        {
          URL: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/${data.guacUsername}/permissions?token=${data.token}`,
          payload: data.payload,
          method: "patch",
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

export const stopInstanceRequest = createAsyncThunk(
  "instance/stop",
  async (payload, thunk) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/instance/labs/stop`,
        payload
      );
      if (result.data.success) {
        if (payload.isStopVictim) {
          thunk.dispatch(setDefaultVictim());
        }
      } else {
        Toast("error", result.data.error.message);
      }
      return result.data;
    } catch (error) {
      Toast("error", "Something went wrong, Couldn't stop lab.");
    }
  }
);

export const deleteConnectionRequestInGuacamole = createAsyncThunk(
  "deleteConnection/guacamole",
  async (data, thunk) => {
    try {
      //   const result = await axios.delete(
      //     `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/connections/${data.id}?token=${data.token}`
      //   );
      console.log("deleteConnectionRequestInGuacamole");
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/guacamole`,
        {
          URL: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/connections/${data.id}?token=${data.token}`,
          payload: {},
          method: "delete",
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

export const deleteUserRequestInGuacamole = createAsyncThunk(
  "deleteUser/guacamole",
  async (data) => {
    try {
      console.log("deleteUserRequestInGuacamole");
      // const result = await axios.delete(
      //   `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/${data.username}?token=${data.token}`
      // );

      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/guacamole`,
        {
          URL: `${process.env.REACT_APP_GUACAMOLE_BASE_URL}/api/session/data/mysql/users/${data.username}?token=${data.token}`,
          payload: {},
          method: "delete",
        }
      );

      return result;
    } catch (error) {
      // Toast("error", "Something went wrong, Couldn't start lab.");
    }
  }
);

// Server Space
export const serverSpaceAuthRequest = createAsyncThunk(
  "serverspace/auth",
  async (_, thunk) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_AWS_BASE_URL}/auth/server-space`
      );

      return result.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const serverSpaceCreateInstanceRequest = createAsyncThunk(
  "serverspace/createInstance",
  async (payload, thunk) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/server-space/createInstance`,
        payload
      );

      return { data: result.data, isStartVictim: payload.isStartVictim };
    } catch (error) {
      return error.response.data;
    }
  }
);

export const serverSpaceCheckInstanceRequest = createAsyncThunk(
  "serverspace/checkInstance",
  async (payload, thunk) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/server-space/checkInstance`,
        payload
      );

      return result.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const serverSpaceGetInstanceRequest = createAsyncThunk(
  "serverspace/getInstance",
  async (payload, thunk) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/server-space/getInstance`,
        payload
      );

      if (result.data.success) {
        if (result.data.hostingPlatform === "Linux")
          thunk.dispatch(
            sshConnectionRequest({
              publicip: result.data.data.nics[0].ip_address,
              username: result.data.data.login,
              password: result.data.data.password,
              saveIn: payload.saveIn,
            })
          );
      }
      return { data: result.data, saveIn: payload.saveIn };
    } catch (error) {
      return error.response.data;
    }
  }
);

export const serverSpaceDeleteInstanceRequest = createAsyncThunk(
  "serverspace/deleteInstance",
  async (payload, thunk) => {
    try {
      const result = await axios.delete(
        `${process.env.REACT_APP_AWS_BASE_URL}/server-space/deleteInstance/${payload}`
      );
      if (result.data.success) thunk.dispatch(setDefaultInstance());
      return result.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// SSH Connection
export const sshConnectionRequest = createAsyncThunk(
  "SSH/connection",
  async (payload, thunk) => {
    try {
      // debugger
      const result = await axios.post(
        `${process.env.REACT_APP_AWS_BASE_URL}/ssh-connection`,
        payload
      );

      return { data: result.data, saveIn: payload.saveIn };
    } catch (error) {
      return error.response.data;
    }
  }
);

// Get Counts in AWS and Server Space

export const getInstanceCountsRequest = createAsyncThunk(
  "counts/instance",
  async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_AWS_BASE_URL}/AWS/server-space/counts`
      );

      return result.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
