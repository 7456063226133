import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const reduxState = useSelector((state) => state);

    return (
      <Component
        {...props}
        router={{ location, navigate, params, dispatch, reduxState }}
      />
    );
  }
  return ComponentWithRouterProp;
};
