import { createSlice } from "@reduxjs/toolkit";
import {
    getAllLabs,
    getAllRepoRequest,
    getLabByIdRequest,
    getLabsByCategoryIdRequest,
    updateLabRepoRequest,
} from "../service/LabService";

const state = {
  isFetching: false,
  error: "",
  allLabs: [],
  labCategory: [],
  labDetails: {},
  uniqueLab: {},
  victim: {},
};

const LabSlice = createSlice({
  name: "labs",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRepoRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getAllRepoRequest.fulfilled, (state, action) => {
      const allLabRepo = action.payload?.labCategory;
      state.isFetching = false;
      state.labCategory = allLabRepo ? allLabRepo : [];
      state.error = !action.payload ? true : false;
    });
    builder.addCase(getAllRepoRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.error;
    });

    //all Labs

    builder.addCase(getAllLabs.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getAllLabs.fulfilled, (state, action) => {
      const allLabRepo = action.payload?.labCategory;
      state.isFetching = false;
      state.allLabs = allLabRepo ? allLabRepo : [];
      state.error = !action.payload ? true : false;
    });
    builder.addCase(getAllLabs.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.error;
    });

    // Lab Repo Update
    builder.addCase(updateLabRepoRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(updateLabRepoRequest.fulfilled, (state, action) => {
      state.isFetching = false;
      state.labCategory = action.payload.labCategory;
    });
    builder.addCase(updateLabRepoRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.error;
    });

    // get Labs by repository ID
    builder.addCase(getLabsByCategoryIdRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getLabsByCategoryIdRequest.fulfilled, (state, action) => {
      const labs = action.payload.data.data;
      const repoId = action.payload.repoId;
      state.isFetching = false;
      if (labs.length !== 0) {
        state.labDetails[labs[0].categoryid] = labs;
      } else {
        state.labDetails[repoId] = [];
      }
    });
    builder.addCase(getLabsByCategoryIdRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.error;
    });

    // Get Unique lab by lab ID
    builder.addCase(getLabByIdRequest.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getLabByIdRequest.fulfilled, (state, action) => {
      const lab = action.payload?.lab;
      state.isFetching = false;
      if (action.payload?.isVictim) {
        state.victim = lab ? lab : {};
      } else {
        state.uniqueLab = lab ? lab : {};
      }
    });
    builder.addCase(getLabByIdRequest.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.payload.data.error;
    });
  },
});

export const { setLabDetails, setUniqueLab, setLabCategory } = LabSlice.actions;
export default LabSlice.reducer;
